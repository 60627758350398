<template>
  <div class="positionT0L0">
    <div class="title">作品/制品信息</div>
    <div class="line">
      <div class="left">作品名称：</div>
      <div class="right">{{ info.productName }}</div>
    </div>
    <div class="line">
      <div class="left">作品展示图片：</div>
      <div
        class="img_box"
        v-if="info.productImageArr.length > 0 && info.productImageArr[0]"
      >
        <img
          v-viewer
          v-for="(item, index) in info.productImageArr"
          :key="index"
          :src="item"
        />
      </div>
    </div>
    <div class="line">
      <div class="left">证明材料图片：</div>
      <div class="img_box">
        <img :src="info.productPubAnnex" />
      </div>
    </div>
    <div class="line">
      <div class="left">创建时间：</div>
      <div class="right">{{ info.createTime }}</div>
    </div>
    <div class="line">
      <div class="left">创作性质：</div>
      <div class="right">{{ info.productCreateNatName }}</div>
    </div>
    <div class="line">
      <div class="left">作品类别：</div>
      <div class="right">{{ info.productCatName }}</div>
    </div>
    <div class="line">
      <div class="left">创作完成时间：</div>
      <div class="right">{{ info.productCreateTime }}</div>
    </div>
    <div class="line">
      <div class="left">创作完成地点：</div>
      <div class="right">{{ info.productCreateAddr }}</div>
    </div>
    <div class="line">
      <div class="left">发表状态：</div>
      <div class="right">
        {{ info.productPubStateValue == 1 ? "已发表" : "未发表" }}
      </div>
    </div>
    <div class="line">
      <div class="left">发表时间：</div>
      <div class="right">
        {{ info.productPubTime ? info.productPubTime : "- -" }}
      </div>
    </div>
    <div class="line">
      <div class="left">发表地点：</div>
      <div class="right">
        {{ info.productPubAddr ? info.productPubAddr : "- -" }}
      </div>
    </div>
    <div class="line mb40">
      <div class="left">创作意图说明：</div>
      <div class="right">
        {{ info.createIntention ? info.createIntention : "- -" }}
      </div>
    </div>
    <div class="title">作品/制品信息</div>
    <div class="line">
      <div class="left">申请方式：</div>
      <div class="right">{{ info.appMethodName }}</div>
    </div>
    <div class="line">
      <div class="left">权利归属方式：</div>
      <div class="right">{{ info.autBelMethodName }}</div>
    </div>
    <div class="line">
      <div class="left">权利归属方式说明：</div>
      <div class="right">{{ info.autBelMethodExp }}</div>
    </div>
    <div class="line">
      <div class="left">权利拥有状况：</div>
      <div class="right">{{ info.autHoldName }}</div>
    </div>
    <div class="line">
      <div class="left">权利拥有状况说明：</div>
      <div class="right">{{ info.autHoldExp }}</div>
    </div>
    <div class="line">
      <div class="left">权利取得方式：</div>
      <div class="right">{{ info.autAcpMethodName }}</div>
    </div>
    <div class="line">
      <div class="left">权利取得方式说明：</div>
      <div class="right">{{ info.autAcpMethodExp }}</div>
    </div>
    <div class="line">
      <div class="left">作者：</div>
      <div class="right">{{ info.authorsName }}</div>
    </div>
    <div class="line">
      <div class="left">署名：</div>
      <div class="right">{{ info.signature }}</div>
    </div>
    <div class="line">
      <div class="left">著作权人：</div>
      <div class="right">{{ info.copyrightsName }}</div>
    </div>
    <div class="line">
      <div class="left">作品独创性：</div>
      <div class="right">{{ info.productOriginal }}</div>
    </div>
    <div class="line mb40">
      <div class="left">作品附件：</div>
      <div
        class="right"
        v-if="info.enclosureArr.length > 0 && info.enclosureArr[0]"
      >
        <div
          class="fujian_list"
          v-for="(item, index) in info.enclosureArr"
          :key="index"
          @click="enclosureClick(item)"
        >
          <img src="../../../assets/images/icon_fujian.png" />
          <span>{{ item.split("/")[item.split("/").length - 1] }}</span>
        </div>
      </div>
    </div>
    <div class="title">证书信息</div>
    <div class="line">
      <div class="left">证书：</div>
      <div class="img_box2" v-if="info.registerCertificateImgUrlArr[0]">
        <img v-viewer :src="info.registerCertificateImgUrlArr[0]" />
        <img v-viewer :src="info.registerCertificateImgUrlArr[1]" />
      </div>
    </div>
    <div class="line">
      <div class="left">审核纪录：</div>
      <div>
        <a-timeline>
          <a-timeline-item v-for="(item, index) in auditArr" :key="index">
            <p>{{ item.auditTime }}</p>
            <p class="fw" v-if="item.auditStatus == 1">审核中</p>
            <p class="fw" v-if="item.auditStatus == 2">一级审核通过</p>
            <p class="fw" v-if="item.auditStatus == 3">二级审核通过</p>
            <p class="fw" v-if="item.auditStatus == 4">不通过</p>
            <p class="fw2">{{ item.auditOpinion }}</p>
          </a-timeline-item>
        </a-timeline>
      </div>
    </div>
    <FooterToolBar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="() => $router.go(-1)">
        返回
      </a-button>
    </FooterToolBar>
  </div>
</template>

<script>
import { DengjiDetailApi } from "@/request/api/certificate.js";
import FooterToolBar from "@/components/FooterToolbar";
import { baseMixin } from "@/store/app-mixin";
export default {
  mixins: [baseMixin],
  components: { FooterToolBar },
  created() {
    let id = this.$route.params.id;
    DengjiDetailApi({ id: id }).then(({ code, data }) => {
      if (code == 200) {
        this.info = data.copyrightRegistration;
        this.auditArr = data.audit;
        this.info.productImageArr = data.copyrightRegistration.productImage
          ? data.copyrightRegistration.productImage.split(",")
          : [];
        this.info.enclosureArr = data.copyrightRegistration.enclosure
          ? data.copyrightRegistration.enclosure.split(",")
          : [];
        this.info.registerCertificateImgUrlArr = data.copyrightRegistration
          .registerCertificateImgUrl
          ? data.copyrightRegistration.registerCertificateImgUrl.split(",")
          : [];
      }
    });
  },
  data() {
    return {
      info: {
        productImageArr: [],
        enclosureArr: [],
        registerCertificateImgUrlArr: [],
      },
      auditArr: [],
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.title {
  padding: 10px 16px;
  color: #00153c;
  background: #f4f8ff;
  font-weight: 600;
}
.line {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  color: #00153c;
  .left {
    width: 150px;
    text-align: right;
  }
  .img_box {
    img {
      margin-right: 20px;
      width: 90px;
      height: 90px;
    }
  }
  .img_box2 {
    img {
      width: 315px;
      height: 222px;
      margin-right: 20px;
    }
  }
}
.mb40 {
  margin-bottom: 40px;
}
.fw {
  color: #00153c;
  font-weight: 600;
}
.fw2 {
  color: #ccc;
}
</style>
